import React from 'react'
import styled from 'styled-components'
import generateUiGradientStyle from '../utils/uigradientsGenerator'

const Gradient = styled.div`
  /* ${props =>
    generateUiGradientStyle(
      props.preset || 'cherry',
      'linear',
      '100%',
      '100%'
    )} */
  /* background: ${props => props.color}; */
  display: grid;
  place-items: center;
`

export const StreamPoundIcon = props => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.80254 10.5H23.4692"
      stroke="white"
      stroke-width="2.38"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.80254 17.5H23.4692"
      stroke="white"
      stroke-width="2.38"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.8025 3.50002L9.46921 24.5"
      stroke="white"
      stroke-width="2.38"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.8025 3.50002L16.4692 24.5"
      stroke="white"
      stroke-width="2.38"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

const Icon = props => {
  return (
    <Gradient {...props}>
      {/* <StreamPoundIcon style={{ width: '20px', height: '20px' }} /> */}
    </Gradient>
  )
}

export default Icon
