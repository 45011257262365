import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MobileAuthContainer,
} from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
import CheckCircleWhite from '../../icons/checkCircleWhite'
import Circle from '../../icons/circle'
import axios from 'axios'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import {
  departmentStreamsMap,
  pictureGradientPresets,
  presetColorPallete,
} from '../../utils/constants/signup'
import { sample, truncate } from 'lodash'
import StreamIcon from '../../icons/streamIcon'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import AuthSidebar from '../../components/authSidebar'
import { isMobileView } from '../../utils/isMobileView'
import MobileTopBar from '../../components/mobileTopBar'
// import { Gradient } from 'uigradients'

const SetupStreamsContainer = () => (
  <AuthProvider>
    <SetupStreams />
  </AuthProvider>
)

const FormBox = styled.div`
  /* margin-top: 89px; */
  width: 100%;
  display: flex;
  justify-content: center;
`

const Header = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

const StreamPill = styled.button`
  transition: all 200ms ease-in-out;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: black;
  width: 344px;
  padding: 16px;
  cursor: pointer;
  background-color: white;
  height: 72px;
  outline: 0;
  /* margin-bottom: 12px;
  margin-right: 12px; */
  position: relative;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  @media (max-width: 769px) {
  }
  ${props =>
    props.selected
      ? css`
          background-color: black;
          color: white;
        `
      : ''};
  ${props =>
    props.disabled
      ? css`
          cursor: not-allowed;
        `
      : ''};
`

const StreamBox = styled.div`
  width: 700px;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  @media (max-width: 769px) {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 4px;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    padding: 0px 24px;
    box-sizing: border-box;
  }
`

const StreamLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  ${props =>
    props.selected
      ? css`
          color: white;
        `
      : ''};
`

const StreamDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  ${props =>
    props.selected
      ? css`
          color: #cbcbcb;
        `
      : css`
          color: #757575;
        `};
`

const SetupStreams = () => {
  const {
    token,
    signupStepsMap,
    setLoading,
    userSession,
    navigateAuth,
  } = useContext(AuthContext)
  const [streamSuggestions, setStreamSuggestions] = useState([])

  const [selectedStreams, setSelectedStreams] = useState([])

  const [error, setError] = useState(null)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useEffect(() => {
    if (userSession) {
      setSelectedStreams(departmentStreamsMap.general)
      setStreamSuggestions([
        ...departmentStreamsMap.general,
        ...(departmentStreamsMap[userSession.department]
          ? departmentStreamsMap[userSession.department].map(stream => ({
              ...stream,
              pictureGradient: sample(pictureGradientPresets),
              color: sample(presetColorPallete),
            }))
          : []),
      ])
    }
  }, [userSession])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await axios.post(
        `${config.backendUri}/auth/session`,
        {
          streams: selectedStreams,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      navigateAuth(signupStepsMap.get(result.data.signupStep))
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  useOnEnterEventListener(true, () => {
    handleSubmit()
  })

  useHotjarScript()

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Setup streams" />
        <MobileTopBar>Setup streams</MobileTopBar>
        <div
          style={{
            marginTop: '40px',
          }}
        >
          <div
            style={{
              padding: '0px 24px',
            }}
          >
            <AuthTitle>Set up Streams</AuthTitle>
            <CopySubText
              style={
                {
                  // marginTop: '16px',
                  // fontSize: '14px',
                  // lineHeight: '22px',
                }
              }
            >
              Organize ideas, video, discussions and much more in Streams. Here
              are some we generated to help you get started. Edit or add new
              ones later.
            </CopySubText>
          </div>
          <Box style={{ marginTop: '24px' }}>
            <StreamBox>
              {streamSuggestions.map((stream, index) => {
                const isSelected = selectedStreams
                  .map(s => s.name)
                  .includes(stream.name)
                return (
                  <StreamPill
                    selected={isSelected}
                    disabled={index === 0}
                    onClick={() => {
                      if (index === 0) {
                        return
                      }
                      if (isSelected) {
                        setSelectedStreams(
                          selectedStreams.filter(
                            selected => selected.name !== stream.name
                          )
                        )
                      } else {
                        setSelectedStreams([
                          ...new Set([...selectedStreams, stream]),
                        ])
                      }
                    }}
                  >
                    <StreamIcon
                      style={{
                        width: '32px',
                        height: '32px',
                        background: stream.color,
                        borderRadius: '50%',
                      }}
                      // preset={stream.pictureGradient}
                      // color={stream.color}
                    />
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        alignItems: 'start',
                        marginLeft: '16px',
                      }}
                    >
                      <StreamLabel selected={isSelected}>
                        {stream.name}
                      </StreamLabel>
                      <StreamDescription selected={isSelected}>
                        {truncate(stream.description, { length: 33 })}
                      </StreamDescription>
                    </Box>
                    {isSelected ? (
                      <CheckCircleWhite
                        style={{ position: 'absolute', right: '14px' }}
                      />
                    ) : (
                      <Circle style={{ position: 'absolute', right: '14px' }} />
                    )}
                  </StreamPill>
                )
              })}
            </StreamBox>
          </Box>
          {error && (
            <Box style={{ marginTop: '24px' }}>
              <ErrorBanner>{error}</ErrorBanner>
            </Box>
          )}
          <div
            style={{
              padding: '24px 24px 38px 24px',
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 38.54%)',
              position: 'fixed',
              left: 0,
              bottom: 0,
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Button onClick={() => handleSubmit()}>Continue</Button>
          </div>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Setup streams" />
      {/* <WorkspaceSetupSidebar step={3} subStep={2} remainingTime="2 min" /> */}
      <Container>
        <AuthSidebar currentStepIndex={3} />
        <FormBox>
          <Box style={{ width: '724px' }}>
            {/* <Header style={{ color: 'black', fontSize: '20px' }}>
              Some ideas based on your goals
            </Header> */}
            <AuthTitle>Set up Streams</AuthTitle>
            <CopySubText
              style={{
                marginTop: '16px',
                // fontSize: '14px',
                // lineHeight: '22px',
              }}
            >
              Organize ideas, video, discussions and much more in Streams. Here
              are some we generated to help you get started. Edit or add new
              ones later.
            </CopySubText>
            <Box style={{ marginTop: '24px' }}>
              <StreamBox>
                {streamSuggestions.map((stream, index) => {
                  const isSelected = selectedStreams
                    .map(s => s.name)
                    .includes(stream.name)
                  return (
                    <StreamPill
                      selected={isSelected}
                      disabled={index === 0}
                      onClick={() => {
                        if (index === 0) {
                          return
                        }
                        if (isSelected) {
                          setSelectedStreams(
                            selectedStreams.filter(
                              selected => selected.name !== stream.name
                            )
                          )
                        } else {
                          setSelectedStreams([
                            ...new Set([...selectedStreams, stream]),
                          ])
                        }
                      }}
                    >
                      <StreamIcon
                        style={{
                          width: '32px',
                          height: '32px',
                          background: stream.color,
                          borderRadius: '50%',
                        }}
                        preset={stream.pictureGradient}
                      />
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          flexDirection: 'column',
                          alignItems: 'start',
                          marginLeft: '16px',
                        }}
                      >
                        <StreamLabel selected={isSelected}>
                          {stream.name}
                        </StreamLabel>
                        <StreamDescription selected={isSelected}>
                          {truncate(stream.description, { length: 33 })}
                        </StreamDescription>
                      </Box>
                      {isSelected ? (
                        <CheckCircleWhite
                          style={{ position: 'absolute', right: '14px' }}
                        />
                      ) : (
                        <Circle
                          style={{ position: 'absolute', right: '14px' }}
                        />
                      )}
                    </StreamPill>
                  )
                })}
              </StreamBox>
            </Box>
            <Button
              style={{ marginTop: '24px' }}
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
            {error && (
              <Box style={{ marginTop: '16px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default SetupStreamsContainer
